#root, body, html {
    height: 100%;
}

#bgImage {
    background: url("../img/LandingPageBgClean.jpg");
    background-size: cover;

    position: fixed;
    filter: blur(8px);
    -webkit-filter: blur(8px);
    z-index: -1;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

#bgColorOverlay {
    background: linear-gradient(rgba(42, 43, 98, 0.5), rgba(42, 43, 98, 0.5));
    z-index: -1;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.h2 {
    font-size: 20px;
}

.full-row {
    height: 100%;
}

#landingPageLeftCol {
    background: rgba(255, 255, 255, 1);
    height: 100vh;
}

#landingPageRightCol {
    color: white;
    vertical-align: center;
    text-align: center;
}

.loginFormBorder {

}

.container {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.bottomInformationRow {
    bottom: 0px;
    position: fixed;

    /*vertical-align: center;
    color: white;
    padding-top: 10px;
    padding-left: 15px;
    margin-left: -15px;
    height: 40px;
    background-color: darkgray;
    position: fixed;
    bottom: 0;
    width: inherit;*/
}

.bottomLink {
    color: black;
}

.bottomLink:hover {
    color: black;
}

.main-row {
    height: 100%;
    margin-bottom: 0;
}

.main-container {
    min-height: 100%;
}

#loginSignupSelectorItem {
    text-align: center;
    width: 50%;
    margin-bottom: 20px;
}

#spacer {
    padding: 15% 40px 40px 40px;
    text-align: left;
}

#loginSignupSelector {
    width: 100%;
    margin-bottom: 20px;
    margin-right: 0;
}

#rightColContent {
    padding: 40% 40px 40px 40px;
}

.loader {
    margin-top: 20px;
    border: 15px solid #f3f3f3; /* Light grey */
    border-top: 15px solid #3498db; /* Blue */
    border-bottom: 15px solid #3498db; /* Blue */
    border-radius: 50%;
    width: 80px;
    height: 80px;
    animation: spin 2s linear infinite;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}