/*.App {
  text-align: center;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 80px;
}

.App-header {
  background-color: #222;
  height: 150px;
  padding: 20px;
  color: white;
}

.App-title {
  font-size: 1.5em;
}

.App-intro {
  font-size: large;
}

@keyframes App-logo-spin {
  from { transform: rotate(0deg); }
  to { transform: rotate(360deg); }
}

font-family: 'Lato', sans-serif;
*/

html, body {
    width: 100%;
    padding-right: 0;
    padding-left: 0;

}

.container-fluid .App #root{
    width: 100%;
    padding-right: 0;
    padding-left: 0;
}